import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { EventListFAObject, UserEventListObject } from '../models/user-event-list-object';
import { HttpClient } from '@angular/common/http';
import { Event, EventObject } from '../models/event';
import { EventCreate } from '../models/event-create';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private http: HttpClient) {}
  
  getUserEventList(startDate: string, endDate: string, userIdList: string, emptyEvent: number = 1, detailEvent: number = 1, detailCostEvent: number = 1): Observable<UserEventListObject> {
    return this.http.get<UserEventListObject>('/api/UserEventList?DateStart=' + startDate + '&DateEnd=' + endDate + '&UserList=' + userIdList + '&EmptyEvent=' + emptyEvent + '&DetailEvent=' + detailEvent + '&DetailCostEvent=' + detailCostEvent);
  }

  getEventListFA(ficheActionId: number): Observable<EventListFAObject> {
    return this.http.get<EventListFAObject>('/api/EventListFA?FAId=' + ficheActionId);
  }

  createEvent(_eventCreated: EventCreate, _forcedCreation: number): Observable<EventObject> {
    return this.http.post<EventObject>('/api/Event?ForcedCreation=' + _forcedCreation, _eventCreated);
  }

  modifEvent(_eventModif: Event, _forcedCreation: number): Observable<EventObject> {
    return this.http.put<EventObject>('/api/Event?ForcedCreation=' + _forcedCreation, _eventModif);
  }

  deleteEvent(_eventDeleted: Event): Observable<EventObject> {
    return this.http.delete<EventObject>('/api/Event?EventID=' + _eventDeleted.EventId);
  }
}
