import { CommonModule, DatePipe, Location } from '@angular/common';
import { Component } from '@angular/core';
import { IconComponent } from '../widgets/icon/icon.component';
import { TooltipUserComponent } from '../tooltip-user/tooltip-user.component';
import { Event } from '../models/event';
import { EventService } from '../services/event.service';
import { UserEventListObject } from '../models/user-event-list-object';
import { Planning } from '../models/planning';
import { constructorDayUserEvent, DayUserEvent } from '../models/day-user-event';
import { EventCardComponent } from "../event-card/event-card.component";
import { constructorUser, User } from '../models/user';
import { EventEmitter } from 'stream';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ParamService } from '../services/param.service';

@Component({
  selector: 'app-planning',
  standalone: true,
  imports: [CommonModule, IconComponent, TooltipUserComponent, EventCardComponent, ReactiveFormsModule, FormsModule],
  providers: [DatePipe],
  templateUrl: './planning.component.html',
  styleUrl: './planning.component.scss'
})

export class PlanningComponent {

  isTooltipPlanningOpen: boolean = false;
  usersPlanning!: Planning;
  listUserSelected: string = "";
  tabUserSelected!: User[];

  monTableauAssociatif: { [key: string]: DayUserEvent[] } = {};
  tabDayUserEvent: DayUserEvent[] = [];
  isLoaded: boolean = false;
  dateValue: string = ""
  dateValueDate: Date = new Date();

  dateToday!: Date;
  dateTomorow!: Date;
  dateTotomorow!: Date;

  dateSelected!: Date | null;

  constructor(
    private location: Location,
    private eventSvc: EventService,
    private datePipe: DatePipe,
    private paramSvc: ParamService,
  ) {
    this.paramSvc.getEntity();
  }

  backToCalendar() {
    this.location.back();
  }

  closeTooltipUser(_event?: boolean) {
    this.isTooltipPlanningOpen = !this.isTooltipPlanningOpen;
  }

  getUserEventList(dateStart: string, dateEnd: string, userList: string) {
    this.tabDayUserEvent = [];
    this.eventSvc.getUserEventList(dateStart, dateEnd, userList, 1, 0, 0).subscribe(
      {
        next: (_userEventList: UserEventListObject) => {
          if (_userEventList) {
            this.listUserSelected = _userEventList?.UserEventListUserList;
            this.usersPlanning = _userEventList.UserEventListPlanning; 
            this.generatePlanningObject();
            this.isLoaded = true;
          }
        },
        error: _error => {
          console.log("Pas de réponse pour le planning de ces utilisateurs", _error);
          if (_error.error) {
            alert("Erreur lors du chargement du planning de ces utilisateurs : " + _error.error.Error.ErrorMessage);
          }
        }
      }
    )
  }

  generatePlanningObject() {
    this.listUserSelected.split(';').map(_userId => {
      let planningbyUserEvents: DayUserEvent = constructorDayUserEvent();

      this.usersPlanning.PlanningDayUserEvents.map(_planning => {
        if (String(_planning.DayUserEventUser.UserId) == _userId) {
          //Set new User Object
          planningbyUserEvents.DayUserEventUser.UserId = _planning.DayUserEventUser.UserId;
          planningbyUserEvents.DayUserEventUser.UserName = _planning.DayUserEventUser.UserName;
          planningbyUserEvents.DayUserEventUser.UserShortName = _planning.DayUserEventUser.UserShortName;
          planningbyUserEvents.DayUserEventUser.UserEntityLabel= _planning.DayUserEventUser.UserEntityLabel;
          planningbyUserEvents.DayUserEvents = planningbyUserEvents.DayUserEvents.concat(_planning.DayUserEvents);
        }
      });
      this.tabDayUserEvent.push(planningbyUserEvents);
    });
  }

  dateChange(_eventDate: string) { 
    if (_eventDate) {
      let _dateToday = new Date(_eventDate);
      this.dateSelected = _dateToday;
      this.dateToday = new Date(_eventDate);
      this.dateTomorow = new Date(_dateToday.setDate(_dateToday.getDate() + 1));
      this.dateTotomorow = new Date(_dateToday.setDate(_dateToday.getDate() + 1));
      this.selectDate(this.dateToday);
    } else {
      this.dateSelected = null;
    }
  }

  selectDate(_date: Date) {
    this.dateSelected = _date;
    let dateDayParam: string | null = this.datePipe.transform(_date,"yyyyMMdd");
    if (dateDayParam && this.listUserSelected) {
      this.getUserEventList(dateDayParam, dateDayParam, this.listUserSelected);
    }
  } 

  getUserSelectedFromTooltip(_userList: User[]) {
    
    this.setUserListParam(_userList);

    if (this.dateSelected && this.listUserSelected != "") {
      this.selectDate(this.dateToday);
    }
  }
  
  //Refresh the string param to reload correctly the next time
  setUserListParam(_userList: User[]) {
    this.listUserSelected = "";
    this.tabUserSelected = _userList;
    let _tempUserList: number[] = [];

    _userList.map(_user => {
      _tempUserList.push(_user.UserId);
    });

    //Concat with ";" an array into string
    this.listUserSelected = _tempUserList.join(";");
  }

  //Unselect User mean tell to the child (tooltip-user) the new userList
  unselectUser(_userToDelete: User) {    
    //Delete user from list user selected
    this.tabUserSelected = this.tabUserSelected.filter(_user => _user.UserId !== _userToDelete.UserId);
    //Update interface
    this.tabDayUserEvent = this.tabDayUserEvent.filter(_dayUserEvent => _dayUserEvent.DayUserEventUser.UserId !== _userToDelete.UserId);
    //Refresh the string param to reload correctly the next time
    this.setUserListParam(this.tabUserSelected);
  }
}
