import { Error } from "./error";

export interface User {
    UserId: number;
    UserName: string;
    UserShortName: string;
    UserEntityLabel: string;
    UserAutoVehicule: number;
    UserAuthUsers: string[];
    UserChecked?: boolean;
    UserActivityRight: boolean;
}

export interface UserObject {
    User: User
    Error: Error
}

export function constructorUser(): User {
    return {
        UserId: 0,
        UserName: '',
        UserShortName: '',
        UserEntityLabel: '',
        UserAutoVehicule: 0,
        UserAuthUsers: [],
        UserActivityRight: false,
    }
}

export function constructorArrayUser(): User[] {
    return [
        constructorUser()
    ]
}